import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { userToolStore } from '@/utils/store-accessor';
import { mainStore } from '@/store';
let CustomTabsComercialComponent = class CustomTabsComercialComponent extends Vue {
    constructor() {
        super(...arguments);
        this.publicItems = [
            { title: 'Driver', route: '/main/Comercial/all', toolName: '', value: 0 },
            {
                title: 'Parcerias',
                route: '/main/ComercialParcerias/all',
                toolName: 'Parcerias',
                value: 1,
            },
            {
                title: 'Campanhas',
                route: '/main/ComercialCampanhas/all',
                toolName: 'Campanhas',
                value: 2,
            },
            {
                title: this.getNomeMarketingDigital(),
                route: '/main/DrivingMarketing/all',
                toolName: 'Driving Marketing',
                value: 3,
            },
            {
                title: 'Quadro de planej.',
                route: '/main/QuadrosPlanejamentocomercial/all',
                toolName: 'Planejamento comercial',
                value: 4,
            },
            {
                title: 'Cupons',
                route: '/main/ComercialCupons/all',
                toolName: 'Cupons',
                value: 5,
            },
            {
                title: 'Saldo de parcerias',
                route: '/main/ComercialCadernodecupom/all',
                toolName: 'Caderno de cupom',
                value: 6,
            },
            {
                title: this.getNomeCDA(),
                route: '/main/ComercialCDA/all',
                toolName: 'CDA',
                value: 7,
            },
            {
                title: this.userProfile.company.escola_id == 1 ? 'Prospex' : 'Check-in',
                route: '/main/Operacionalprospex/all',
                toolName: 'Prospex',
                value: 8,
            },
            {
                title: 'Caderno de matrículas',
                route: '/main/ComercialCadernodematriculas/matricula',
                toolName: 'Caderno de matrículas',
                value: 9,
            },
            {
                title: 'Check-up comercial',
                route: '/main/checkup/all',
                toolName: 'Check-up',
                value: 10,
            },
            {
                title: 'Metas',
                route: '/main/ComercialMetas/all',
                toolName: 'Metas',
                value: 11,
            },
        ];
        this.internalSelectedTab = 0;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get filteredItems() {
        return this.publicItems.filter((item) => this.allowView(item.toolName));
    }
    allowView(toolName) {
        if (!toolName)
            return true;
        const tool = userToolStore.userToolsIndexed[toolName];
        return tool ? tool[0].allow_view : false;
    }
    onSelectedTabChange(newTab) {
        const index = this.filteredItems.findIndex((item) => item.value === newTab);
        this.internalSelectedTab = index !== -1 ? index : 0;
    }
    onRouteChange() {
        const tabIndex = this.getTabIndexFromRoute(this.$route.path);
        this.internalSelectedTab = tabIndex;
        this.$emit('update:selectedTab', this.filteredItems[tabIndex]?.value || 0);
    }
    handleTabClick(item, index) {
        if (this.internalSelectedTab !== index) {
            this.internalSelectedTab = index;
            this.$emit('update:selectedTab', item.value);
        }
        this.redirectToRoute(item.route);
    }
    redirectToRoute(route) {
        if (this.$route.path !== route) {
            this.$router.push(route);
        }
    }
    mounted() {
        const tabIndex = this.getTabIndexFromRoute(this.$route.path);
        this.internalSelectedTab = tabIndex;
    }
    getTabIndexFromRoute(route) {
        const itemIndex = this.filteredItems.findIndex((item) => item.route === route);
        return itemIndex !== -1 ? itemIndex : 0;
    }
};
__decorate([
    Prop({ type: Number, default: 0 })
], CustomTabsComercialComponent.prototype, "selectedTab", void 0);
__decorate([
    Watch('selectedTab', { immediate: true })
], CustomTabsComercialComponent.prototype, "onSelectedTabChange", null);
__decorate([
    Watch('$route')
], CustomTabsComercialComponent.prototype, "onRouteChange", null);
CustomTabsComercialComponent = __decorate([
    Component
], CustomTabsComercialComponent);
export default CustomTabsComercialComponent;
